import {defineStore} from 'pinia';
import axios from 'axios';
import { useRouter } from 'vue-router'
import Swal from 'sweetalert2'

    axios.defaults.baseURL = 'http://31.220.84.241:8080/api'
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
    axios.defaults.headers.common['Access-Control-Allow-Credentials']= false
    axios.defaults.headers.common['Accept'] = 'application/json'
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
    axios.defaults.withCredentials = false
    const router = useRouter()



export const useAuthStore = defineStore('auth',{
    state: () => ({ 
        authUser: null,
        accessToken: null,
        data: []
    }),
    getters:{
        user:(state) => state.authUser,
        token:(state) => state.accessToken
    },
    actions:{
        async login(form){
            console.log(form.value)
            await axios.post('/login',form.value).then(
                (res) => {

                    const instance = axios.create({});
                    instance.defaults.baseURL = 'http://31.220.84.241:8080/api'
                    instance.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
                    instance.defaults.headers.common['Access-Control-Allow-Credentials']= false
                    instance.defaults.headers.common['Accept'] = 'application/json'
                    instance.defaults.headers.common['Content-Type'] = 'application/json'
                    instance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
                    instance.defaults.headers.common['user_token'] = res.data.accessToken
                    instance.defaults.withCredentials = false





                    instance.get('/getClients', {}).then(
                        (res) => {
                            this.data = JSON.stringify(res.data)
                            console.log(res.data)
                            if(res.data.error){
                                Swal.fire('Usuario o clave incorrecta', '', 'info') 
                            }
                        }
                    ).catch(
                        (errors) => {
                            console.log(errors)
                            Swal.fire('Error de sistema, intenta nuevamente', '', 'info') 
                            /*let desc = '';
                            errors.response.data.errors.map(
                                (e: any) => {desc = desc + ''+e}
                            )*/
                        }
                    )



                    console.log(res.data.accessToken)
                    this.accessToken = res.data.accessToken;
                    this.router.push({ name: 'clientes' })
                    //this.$router.push({ path: '/' });
                }
            ).catch(
                (errors) => {
                    console.log(errors)
                    /*let desc = '';
                    errors.response.data.errors.map(
                        (e: any) => {desc = desc + ''+e}
                    )*/
                }
            )
        },
        async logouth(){
            this.accessToken = null;
            this.router.push('/login');
        },

    },
    persist: true,
})